/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "./global-settings-sidebar.scss";
import { RootReducer } from "../../../store/reducers/root-reducer";
import { panelIdToSetupAction } from "../../../store/reducers/panel-to-set/actions/panel-to-set-action";
import { resetPreviousPanelInfoAction } from "../../../store/reducers/prev-panel/actions/prev-panel-action";
import { User } from "../../../interfaces/user-detail-interface";

type GlobalSettingsSidebarProps = {
  id?: string;
  setActiveSettingPanel?: (panel: string) => void;
  onItemClickMobile?: () => void;
};

function GlobalSettingsSidebar({
  id,
  setActiveSettingPanel = () => undefined,
  onItemClickMobile = () => undefined,
}: GlobalSettingsSidebarProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch<any>();

  const isPanelSettingsView = location.pathname
    .split("/")
    .find((path) => path === "settings");

  const prevPanel = useSelector(
    (state: RootReducer) => state.previousPanelInfo
  );

  const getBackPath = () => {
    const path = "/panels";
    if (!prevPanel.panelId) {
      return path;
    }
    if (prevPanel.subpanelId) {
      return `${path}/${prevPanel.panelId}/${prevPanel.subpanelId}`;
    }

    return `${path}/${prevPanel.panelId}`;
  };

  const backPath = () => {
    const path = getBackPath();
    dispatch(resetPreviousPanelInfoAction());
    navigate(path);
  };

  const { panelIdToSetup } = useSelector(
    (state: RootReducer) => state.panelIdToSetup
  );

  const { isParticipant }: User = useSelector(
    (state: RootReducer) => state.user
  );

  return (
    <div className="settings-container-sidebar">
      <ul className="global-settings-sidebar-list-items">
        {id ? (
          <li>
            <button
              type="button"
              className="individual-settings-back-button"
              title={t<string>("backToPanelsButton")}
              onClick={(e) => {
                onItemClickMobile?.();
                e.preventDefault();
                if (params.subpanelId && !panelIdToSetup) {
                  dispatch(
                    panelIdToSetupAction({ panelIdToSetup: params.subpanelId })
                  );
                }
                if (panelIdToSetup) {
                  dispatch(panelIdToSetupAction({ panelIdToSetup: "" }));
                  if (params.subpanelId) {
                    navigate(`/panels/${params.panelId}/${params.subpanelId}`);
                  } else {
                    navigate(`/panels/${params.panelId}`);
                  }
                }
              }}
            >
              <ArrowLeftOutlined />
              <span style={{ paddingLeft: "4px" }}>
                {t<string>("backToPanelsButton")}
              </span>
            </button>
          </li>
        ) : (
          <>
            {" "}
            <li>
              <button
                type="button"
                className="global-settings-back-button"
                title={t<string>("backToPanelsButton")}
                onClick={backPath}
              >
                <ArrowLeftOutlined />
                <span style={{ paddingLeft: "4px" }}>
                  {t<string>("backToPanelsButton")}
                </span>
              </button>
            </li>
            <li>
              <Link
                className="global-settings-sidebar-items"
                to="/globalsettings/profile"
                onClick={() => onItemClickMobile?.()}
              >
                {t<string>("globalSettings.profile.title")}
              </Link>
            </li>
            {!isParticipant && (
              <li>
                <Link
                  className="global-settings-sidebar-items"
                  to="/globalsettings/subscription"
                  onClick={() => onItemClickMobile?.()}
                >
                  {t<string>("globalSettings.subscription.title")}
                </Link>
              </li>
            )}
            <li>
              <Link
                className="global-settings-sidebar-items"
                to="/globalsettings/data-sources"
                onClick={() => onItemClickMobile?.()}
              >
                {t<string>("globalSettings.dataSources.title")}
              </Link>
            </li>
          </>
        )}
        <li>
          <div className="global-settings-sidebar-items disabled">
            {t<string>("globalSettings.sourceView.title")}
          </div>
        </li>
        <ul className="global-settings-source-view-sidebar-items">
          <li>
            <Link
              className={`global-settings-sidebar-items ${
                isPanelSettingsView && params.source === "o365"
                  ? "global-settings-sidebar-individual"
                  : ""
              }`}
              to="/globalsettings/o365"
              onClick={(e) => {
                onItemClickMobile?.();
                if (id) {
                  e.preventDefault();
                  setActiveSettingPanel("o365");
                  if (params.subpanelId) {
                    navigate(
                      `/panels/${params.panelId}/${params.subpanelId}/settings/o365`
                    );
                  } else {
                    navigate(`/panels/${params.panelId}/settings/o365`);
                  }
                }
              }}
            >
              {t<string>("globalSettings.sourceView.items.o365.title")}
            </Link>
          </li>

          <li>
            <Link
              className={`global-settings-sidebar-items ${
                isPanelSettingsView && params.source === "gmail"
                  ? "global-settings-sidebar-individual"
                  : ""
              }`}
              to="/globalsettings/gmail"
              onClick={(e) => {
                onItemClickMobile?.();
                if (id) {
                  e.preventDefault();
                  setActiveSettingPanel("gmail");
                  if (params.subpanelId) {
                    navigate(
                      `/panels/${params.panelId}/${params.subpanelId}/settings/gmail`
                    );
                  } else {
                    navigate(`/panels/${params.panelId}/settings/gmail`);
                  }
                }
              }}
            >
              {t<string>("globalSettings.sourceView.items.gmail.title")}
            </Link>
          </li>

          <li>
            <Link
              className={`global-settings-sidebar-items ${
                isPanelSettingsView && params.source === "slack"
                  ? "global-settings-sidebar-individual"
                  : ""
              }`}
              to="/globalsettings/slack"
              onClick={(e) => {
                onItemClickMobile?.();
                if (id) {
                  e.preventDefault();
                  setActiveSettingPanel("slack");
                  if (params.subpanelId) {
                    navigate(
                      `/panels/${params.panelId}/${params.subpanelId}/settings/slack`
                    );
                  } else {
                    navigate(`/panels/${params.panelId}/settings/slack`);
                  }
                }
              }}
            >
              {t<string>("globalSettings.sourceView.items.slack.title")}
            </Link>
          </li>

          <li>
            <Link
              className={`global-settings-sidebar-items ${
                isPanelSettingsView && params.source === "gdrive"
                  ? "global-settings-sidebar-individual"
                  : ""
              }`}
              to="/globalsettings/gdrive"
              onClick={(e) => {
                onItemClickMobile?.();
                if (id) {
                  e.preventDefault();
                  setActiveSettingPanel("gdrive");
                  if (params.subpanelId) {
                    navigate(
                      `/panels/${params.panelId}/${params.subpanelId}/settings/gdrive`
                    );
                  } else {
                    navigate(`/panels/${params.panelId}/settings/gdrive`);
                  }
                }
              }}
            >
              {t<string>("globalSettings.sourceView.items.gdrive.title")}
            </Link>
          </li>

          <li>
            <Link
              className={`global-settings-sidebar-items ${
                isPanelSettingsView && params.source === "one-drive"
                  ? "global-settings-sidebar-individual"
                  : ""
              }`}
              to="/globalsettings/one-drive"
              onClick={(e) => {
                onItemClickMobile?.();
                if (id) {
                  e.preventDefault();
                  setActiveSettingPanel("one-drive");
                  if (params.subpanelId) {
                    navigate(
                      `/panels/${params.panelId}/${params.subpanelId}/settings/one-drive`
                    );
                  } else {
                    navigate(`/panels/${params.panelId}/settings/one-drive`);
                  }
                }
              }}
            >
              {t<string>("globalSettings.sourceView.items.oneDrive.title")}
            </Link>
          </li>

          <li>
            <Link
              className={`global-settings-sidebar-items ${
                isPanelSettingsView && params.source === "dropbox"
                  ? "global-settings-sidebar-individual"
                  : ""
              }`}
              to="/globalsettings/dropbox"
              onClick={(e) => {
                onItemClickMobile?.();
                if (id) {
                  e.preventDefault();
                  setActiveSettingPanel("dropbox");
                  if (params.subpanelId) {
                    navigate(
                      `/panels/${params.panelId}/${params.subpanelId}/settings/dropbox`
                    );
                  } else {
                    navigate(`/panels/${params.panelId}/settings/dropbox`);
                  }
                }
              }}
            >
              {t<string>("globalSettings.sourceView.items.dropbox.title")}
            </Link>
          </li>
        </ul>
      </ul>
    </div>
  );
}

export default GlobalSettingsSidebar;
