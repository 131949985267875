/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/require-default-props */
import { SetStateAction, useState } from "react";
import { useParams, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Navbar from "../navbar/navbar";

import { GlobalSettingsParams } from "../../prop-types/prop-type";
import { Subscription } from "./subscription";
import DataSources from "./data-sources";
import Profile from "./profile";
import EmailO365 from "./email-o365";
import Gmail from "./gmail";
import Slack from "./slack";
import OneDrive from "./one-drive";
import Dropbox from "./dropbox";
import GDrive from "./gdrive";
import GlobalSettingsPanel from "../global-settings/global-settings-panel";
import GlobalSettingsSidebar from "../global-settings/global-setting-sidebar/global-settings-sidebar";
import { Child, Panel } from "../../interfaces/user-detail-interface";
import "./settings.scss";
import { RootReducer } from "../../store/reducers/root-reducer";

export type CompProps = { id?: string; item?: Child };

export const Comp = ({ id: idFromProps, item }: CompProps) => {
  const params = useParams();
  const panels = useSelector((state: RootReducer) => state.panels.activePanels);

  let propItem = item;

  if (!item && params.subpanelId) {
    const currectParentPanel = panels.find(
      (panel: Panel) => panel.id === params.panelId
    );
    const currectPanel = currectParentPanel.children.find(
      (c: Child) => c.id === params.subpanelId
    );
    propItem = currectPanel;
  }
  const { id } = useParams<GlobalSettingsParams>();
  switch (params.source || idFromProps || id?.toString().toLowerCase()) {
    case "subscription":
      return <Subscription />;
    case "data-sources":
      return <DataSources />;
    case "profile":
      return <Profile />;
    case "o365":
      return <EmailO365 item={propItem} />;
    case "gmail":
      return <Gmail item={propItem} />;
    case "slack":
      return <Slack item={propItem} />;
    case "gdrive":
      return <GDrive item={propItem} />;
    case "one-drive":
      return <OneDrive item={propItem} />;
    case "dropbox":
      return <Dropbox item={propItem} />;
    default:
      return <Navigate to="/pathError" replace />;
  }
};

type SettingsProps = {
  item?: Child;
};

function Settings({ item }: SettingsProps) {
  const [open, setOpen] = useState(true);
  const [visible, setVisible] = useState(false);
  const [showGlobalSettings, setShowGlobalSettings] = useState(false);

  const showDrawer = () => {
    setShowGlobalSettings(!showGlobalSettings);
  };

  const closeGlobalSettings = () => {
    setShowGlobalSettings(false);
  };

  const onClose = () => {
    setVisible(false);
  };

  const params = useParams();
  const idFromProps = item?.id || params.subpanelId;

  let propItem = item;

  const panels = useSelector((state: RootReducer) => state.panels.activePanels);

  if (params.panelId && params.subpanelId) {
    const currectParentPanel = panels.find(
      (panel: Panel) => panel.id === params.panelId
    );
    const currectPanel = currectParentPanel.children.find(
      (c: Child) => c.id === params.subpanelId
    );
    propItem = currectPanel;
  } else if (params.panelId && !params.subpanelId) {
    const currectParentPanel = panels.find(
      (panel: Panel) => panel.id === params.panelId
    );
    propItem = currectParentPanel;
  }

  const activePanelFromPath = params.sourse || "o365";
  const [activeSettingPanel, setActiveSettingPanel] =
    useState(activePanelFromPath);

  return idFromProps ? (
    <div className="settings">
      <div>
        <GlobalSettingsSidebar
          id={idFromProps}
          setActiveSettingPanel={setActiveSettingPanel}
        />
      </div>
      <div className="full-width">
        <Comp id={activeSettingPanel} item={propItem} />
      </div>
    </div>
  ) : (
    <div className="global-settings">
      {open && (
        <div className="global-settings-main-container">
          <GlobalSettingsSidebar />
        </div>
      )}

      <div className="full-width">
        <div className="navbar-wrapper">
          <Navbar open={open} setOpen={setOpen} showDrawer={showDrawer} />
        </div>
        <div
          className={`global-settings-panel-wrapper ${
            showGlobalSettings ? "show-mobile" : ""
          }`}
        >
          {showGlobalSettings && (
            <>
              <div className="overlay" onClick={closeGlobalSettings} />
              <div className="global-settings-mobile">
                <GlobalSettingsSidebar onItemClickMobile={showDrawer} />
              </div>
            </>
          )}
          <GlobalSettingsPanel>
            <Comp />
          </GlobalSettingsPanel>
        </div>
      </div>
    </div>
  );
}

export default Settings;
